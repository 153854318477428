import React, {Fragment} from "react";

class Horarios extends React.Component {
    horariosVerano() {
        return (
            <Fragment>
                <h1 className="card-title">Horario de misas</h1>
                <h4>Horarios a partir del 1 de julio</h4>
                <br/>
                <h3>De Lunes a Viernes a las 20:00 en la Iglesia del Carmen</h3>
                <br/>
                <h3>Sábados</h3>
                <ul className="saturday hour-list">
                    <li>
                        <span className="hour-item">19:00</span> Ermita de Santa
                        María
                    </li>
                    <li>
                        <span className="hour-item">20:00</span> Iglesia de San Sebastián
                    </li>
                </ul>

                <h3>Domingos</h3>
                <ul className="sunday hour-list">
                    <li>
                        <span className="hour-item">10:00</span> Iglesia del
                        Carmen
                    </li>
                    <li>
                        <span className="hour-item">12:00</span> Iglesia de San
                        Sebastián
                    </li>
                    <li>
                        <span className="hour-item">13:00</span> Sagrada Familia
                        (Colonia de Camorritos)
                    </li>
                    <li>
                        <span className="hour-item">20:00</span> Iglesia de San
                        Sebastián
                    </li>
                </ul>
            </Fragment>
        );
    }

    horariosInvierno() {
        return (
            <Fragment>
                <h1 className="card-title">Horario de misas</h1>
                <h4>Horarios a partir del 12 de septiembre</h4>
                <br/>
                <h3>Lunes a Viernes</h3>
                <ul className="hour-list">
                    <li>
                        <span className="hour-item">19:30</span> Iglesia del Carmen
                    </li>
                </ul>
                <h3>Sábados</h3>
                <ul className="saturday hour-list">
                    <li>
                        <span className="hour-item">18:00</span> H. de la
                        Fuenfría
                    </li>
                    <li>
                        <span className="hour-item">19:30</span> Iglesia del
                        Carmen
                    </li>
                </ul>

                <h3>Domingos</h3>
                <ul className="sunday hour-list">
                    <li>
                        <span className="hour-item">10:00</span> Iglesia del
                        Carmen
                    </li>
                    <li>
                        <span className="hour-item">12:00</span> Iglesia de San
                        Sebastián
                    </li>
                    <li>
                        <span className="hour-item">19:30</span> Iglesia del Carmen
                    </li>
                </ul>

                <h3>Rosario</h3>
                <p>
                    El rezo del santo rosario tendrá lugar todos los días,
                    excepto los martes, a las 18:50 en la iglesia del Carmen.
                </p>
                <p>
                    Los martes, a las 18:45, se reza la coronilla de la
                    Misericordia y, a continuación, el rosario.
                </p>
            </Fragment>
        );
    }

    render() {
        return (
            <div className="full-height horarios">
                <h1 className="card-title">Horario de misas</h1>
                <h4>Nos hemos mudado</h4>
                <p>Puede consultar los horarios en la &nbsp;
                    <a href="https://sansebastiancercedilla.archimadrid.es/">
                        nueva página
                    </a>
                </p>
            </div>
        );
    }
}

export default Horarios;
